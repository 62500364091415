module controllers {
    export module master {
        interface ICompanyIncotermScope extends ng.IScope {
        }
        interface ICompanyIncotermParams extends ng.ui.IStateParamsService {
        }

        export class companyIncotermCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', '$anchorScroll', 'bsLoadingOverlayService', '$state', '$timeout', "$stateParams",
                "companyIncotermService", "entityService","shipperService",'consigneeService','transportModeService',"unLocationCodeService", 'incotermService',
                "termsOfDeliveryService",
                "isInbound"
            ];

            companyIncotermList: Array<interfaces.master.ICompanyIncoterm> = [];
            apiCompanyIncotermList: uiGrid.IGridApi;
            entity: interfaces.applicationcore.IDropdownModel;
            entityId: number = undefined;
            IsLoading: boolean = true;
            showAll: boolean = true;
            IsSetRowInactive: boolean = false;
            curentEntityId:number;

            CompanyIncotermSearch: interfaces.master.ICompanyIncotermSearch = {
            };

            constructor(private $scope: ICompanyIncotermScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $anchorScroll: ng.IAnchorScrollService,
                private bsLoadingOverlayService: interfaces.applicationcore.IbsLoadingOverlayService,
                private $state: ng.ui.IStateService,
                private $timeout: ng.ITimeoutService,
                private $stateParams: ICompanyIncotermParams,
                private CompanyIncotermService: interfaces.master.ICompanyIncotermService,
                private entityService: interfaces.applicationcore.IEntityService,
                private shipperService: interfaces.master.IShipperService,
                public consigneeService: services.master.consigneeService,
                public transportModeService: services.applicationcore.transportModeService,
                public unLocationCodeService: interfaces.applicationcore.IUNLocationCodeService,
                public incotermService: services.applicationcore.incotermService,
                public termsOfDeliveryService: interfaces.master.ITermsOfDeliveryService,
                public isInbound: boolean
            ) {
                this.getCurrentEntity();
                this.loadCompanyIncoterms();
            }

            loadCompanyIncoterms(productDescription: string = "", productCode: string = "" ) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'company.incoterm'
                }, () => {
                    //Loads Product Types
                    return this.CompanyIncotermService.getList().query({
                        IsInbound: this.isInbound
                    }, (result: Array<interfaces.master.ICompanyIncoterm>) => {
                        this.companyIncotermList = result;                       
                        this.$timeout(() => {
                            this.gvwCompanyIncotermList.data = result;
                        });
                          this.IsLoading = false;
                    }, (errorResponse) => {
                          this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                          this.IsLoading = false;
                    }).$promise;
                });
            }

            getCurrentEntity() {
                return this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.curentEntityId = result.Id;

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadShippers(searchText: string) {
                
                return this.shipperService.getShipperDropdown(this.curentEntityId, searchText).query({
                }, () => {
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadConsignees(searchText: string) {
                return this.consigneeService.getDropdownList(this.curentEntityId, searchText).query({
                }, (resultList) => {
                }).$promise;
            }

            loadTransportModes() {
                return this.transportModeService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }
            
            
            loadPlaceOfLoading(searchText,ShipperCountryId:number,ConsigneeCountryId:number) {
                var defer = this.$q.defer();

              
                    this.unLocationCodeService.getDropdownList().query({ countryId: this.isInbound?ShipperCountryId:ConsigneeCountryId, searchText: searchText }, (data) => {
                        defer.resolve(data);
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                        defer.resolve([]);
                    });
             

                return defer.promise;
            }

            loadIncoterms() {
                return this.incotermService.getDropdownList().query({
                }, (resultList) => {
                }).$promise;
            }

            loadTermsOfDeliveries(incotermId:number,ShipperEntityId:number,ConsigneeEntityId:number) {
                var defered = this.$q.defer();
 
                
                this.termsOfDeliveryService.getDropdownList().query({ ownerEntityId: this.isInbound?ShipperEntityId:ConsigneeEntityId, incoTermId: incotermId}, (list: Array<interfaces.applicationcore.IDropdownModel>) => {
                    defered.resolve(list);
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    defered.resolve([]);
                });

                return defered.promise;
            }

            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(() => {
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            };

            addNewCompanyIncoterm() {
                var add = <interfaces.master.ICompanyIncoterm>{
                    
                    IsInbound: this.isInbound,
                    IsActive: true,
                };
                (<Array<any>>(this.gvwCompanyIncotermList.data)).splice(0, 0, add);
            };

            registerCompanyIncotermGridApi(gridApi: uiGrid.IGridApi) {
                this.apiCompanyIncotermList = gridApi;

                this.apiCompanyIncotermList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiCompanyIncotermList.selection.selectRow(gridSelection.row.entity);
                });

                this.$timeout(() => {
                    this.gvwCompanyIncotermList.data = this.companyIncotermList;
                });
            };

            companyChanged(model: interfaces.applicationcore.IDropdownModel, row: uiGrid.IGridRow) {
                if (!model) {
                    //row.entity.CostModelElement = undefined;
                }else
                {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'company.incoterm'
                    }, () => {
                        //Loads Product Types
                        return this.CompanyIncotermService.getCompanyIncotermDefaults().query({
                            companyId: model.Id,
                            IsInbound: this.isInbound
                        }, (defaults: interfaces.master.ICompanyIncotermDefaults) => {
                            row.entity.ShipperEntityId = defaults.ShipperEntityId;
                            row.entity.ConsigneeEntityId = defaults.ConsigneeEntityId;
                            row.entity.ShipperCountryId = defaults.ShipperCountryId;
                            row.entity.ConsigneeCountryId = defaults.ConsigneeCountryId;
                            row.entity.ShipperEntity = defaults.ShipperEntity;
                            row.entity.ShipperCountry = defaults.ShipperCountry;
                            row.entity.ShipperSupplier = defaults.ShipperSupplier;
                            row.entity.ShipperSupplierIncoterm = defaults.ShipperSupplierIncoterm;
                            row.entity.ConsigneeEntity = defaults.ConsigneeEntity;
                            row.entity.ConsigneeCountry = defaults.ConsigneeCountry;
                            row.entity.ConsigneeCustomer = defaults.ConsigneeCustomer;
                            row.entity.ConsigneeCustomerIncoterm = defaults.ConsigneeCustomerIncoterm;

                              this.IsLoading = false;
                        }, (errorResponse) => {
                              this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                              this.IsLoading = false;
                        }).$promise;
                    });
                }
            }

            removeInlineCompanyIncoterm(entity: interfaces.master.ICompanyIncoterm) {
                if (entity.Id > 0)
                {
                    entity.IsActive = false;
                    this.apiCompanyIncotermList.rowEdit.setRowsDirty([entity]);
                    this.IsSetRowInactive = true;
                    this.submitCompanyIncoterms()
                }
                else
                {
                    _.remove(this.gvwCompanyIncotermList.data, (o) => {
                        return o === entity;
                    });
                }
            }

            gvwCompanyIncotermList: uiGrid.IGridOptions | any = {
                data: <Array<interfaces.master.ICompanyIncoterm>>[],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                rowEditWaitInterval: -1,
                cellEditableCondition: true,
                paginationPageSizes: [25, 50, 100],
                paginationPageSize: 25,
                showGridFooter: true,
                onRegisterApi: (gridApi) => { this.registerCompanyIncotermGridApi(gridApi); },
                gridMenuShowHideColumns: false,
                enableGridMenu: true,
                exporterMenuCsv: false,
                exporterMenuPdf: false,
                exporterMenuExcel: true,
                exporterExcelFilename: this.isInbound? 'ShipperIncotermList.xlsx' : 'ConsigneeIncotermList.xlsx',
                exporterFieldCallback: (grid, row, col, value) => {
                    return this.generalService.handleValue(value);
                },
                exporterSuppressColumns: ['ADD', 'NUMRECS'],
                columnDefs: [ 
                    {
                        name: "ADD",
                        displayName: "",
                        field: "",
                        cellTemplate: `
                        <div class="GridButton"> 
                            <button type="button" ng-click="grid.appScope.companyIncotermCtrl.removeInlineCompanyIncoterm(row.entity)" class="btn btn-warning btn-sm">
                                Delete
                            </button>
                        </div>`, 
                        enableSorting: false,
                        enableColumnMenu: false,
                        enableCellEditOnFocus: false,
                        width: 55,
                        headerCellTemplate: `
                            <div class="GridButton">
                                <button type="button"  class="btn btn-default btn-sm" ng-click="grid.appScope.companyIncotermCtrl.addNewCompanyIncoterm()">
                                    <span class="fa fa-plus"></span>
                                </button>
                            </div>`
                    }, {
                        name: "ShipperEntity",
                        displayName: "Entity",
                        field: "ShipperEntity",
                        visible: this.isInbound,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.ShipperEntity}}">{{row.entity.ShipperEntity}}</div>                                   
                                      `,
                        width: 80,                  
                        enableCellEdit: false,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: true,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                    },
                    {
                        name: "ConsigneeEntity",
                        displayName: "Entity",
                        visible: !this.isInbound,
                        field: "ConsigneeEntity",
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.ConsigneeEntity}}">{{row.entity.ConsigneeEntity}}</div> 
                                      `,
                        width: 80,                  
                        enableCellEdit: false,
                        enableCellEditOnFocus: true,
                        cellEditableCondition: true,
                        filterHeaderTemplate: `
                        <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                            <div class="input-group-sm">
                                <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                            </div>
                        </div>`
                    },                
                    {
                        name: "SHIPPER",
                        displayName: "* Shipper",
                        field: "Shipper",
                        enableCellEditOnFocus: true,
                        enableCellEdit: true,
                        width: 180,
                        visible: this.isInbound,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyIncotermCtrl.loadShippers(searchText)" pagination-search="true" refresh-delay="1000"
                                        ng-change="grid.appScope.companyIncotermCtrl.companyChanged(model, row)"
                                        ></gts-dropdown>
                                    </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
    
                                if (cellValue) {
                                    return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                }
                                return null;
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiCompanyIncotermList.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }
    
                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }
    
                                return 0;
                            }
                        },
                    },
                    {
                        name: "CONSIGNEE",
                        displayName: "* Consignee",
                        field: "Consignee",
                        enableCellEditOnFocus: true,
                        enableCellEdit: true,
                        width: 180,
                        visible: !this.isInbound,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                        editableCellTemplate: `
                                    <form name="inputForm">
                                        <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyIncotermCtrl.loadConsignees(searchText)" pagination-search="true" refresh-delay="1000"
                                        ng-change="grid.appScope.companyIncotermCtrl.companyChanged(model, row)"
                                        ></gts-dropdown>
                                    </form>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
    
                                if (cellValue) {
                                    return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                                }
                                return null;
                            }
                        },
                        sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                            var nulls = this.apiCompanyIncotermList.core.sortHandleNulls(a, b);
                            if (nulls !== null) {
                                return nulls;
                            } else {
                                if (a === b) {
                                    return 0;
                                }
    
                                if (direction === "desc") {
                                    if (a.Display < b.Display) return -1;
                                    if (a.Display > b.Display) return 1;
                                } else if (direction === "asc") {
                                    if (a.Display > b.Display) return 1;
                                    if (a.Display < b.Display) return -1;
                                }
    
                                return 0;
                            }
                        },
                    },                    
                    
                   
                {
                    name: "ShipperCountry",
                    displayName: "Country",
                    visible: this.isInbound,
                    field: "ShipperCountry",
                    cellTemplate: `<div  ng-if="row.entity.IsInbound " class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.ShipperCountry}}">{{row.entity.ShipperCountry}}</div>                                   
                                  `,
                    width: 120,                  
                    enableCellEdit: false,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },              
                {
                    name: "ConsigneeCountry",
                    displayName: "Country",
                    visible: !this.isInbound,
                    field: "ConsigneeCountry",
                    cellTemplate: `<div  ng-if="!row.entity.IsInbound" class="ui-grid-cell-contents " data-toggle="tooltip" data-placement="top" title="{{row.entity.ConsigneeCountry}}">{{row.entity.ConsigneeCountry}}</div> 
                                  `,
                    width: 120,                  
                    enableCellEdit: false,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },                  
                {
                    name: "SUPPLIER",
                    visible: this.isInbound,
                    displayName: "Supplier",
                    field: "ShipperSupplier",
                    cellTemplate: `<div  ng-if="row.entity.IsInbound" class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.ShipperSupplier}}">{{row.entity.ShipperSupplier}}</div>                                  
                                  `,
                    width: 140,                  
                    enableCellEdit: false,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },   ,
                {
                    name: "CUSTOMER",
                    visible: !this.isInbound,
                    displayName: "Customer",
                    headerCellTemplate:`<div class="ui-grid-cell-contents" ng-if="grid.appScope.companyIncotermCtrl.isInbound">Supplier</div><div class="ui-grid-cell-contents" ng-if="!grid.appScope.companyIncotermCtrl.isInbound">Customer</div>
                   
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>
                    `,                
                    field: "ConsigneeCustomer",
                    cellTemplate: `<div  ng-if="!row.entity.IsInbound " class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.ConsigneeCustomer}}">{{row.entity.ConsigneeCustomer}}</div> 
                                  `,
                    width: 140,                  
                    enableCellEdit: false,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    visible: this.isInbound,
                    name: "SUPPLIERINCOTERM",                    
                    displayName: "Supplier Incoterm",
                    field: "ShipperSupplierIncoterm",
                    cellTemplate: `<div  ng-if="row.entity.IsInbound" class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{row.entity.ShipperSupplierIncoterm}}">{{row.entity.ShipperSupplierIncoterm}}</div>                                   
                                  `,
                    width: 140,                  
                    enableCellEdit: false,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                },
                {
                    name: "CUSTOMERINCOTERM",                    
                    visible: !this.isInbound,
                    displayName: "Customer Incoterm",
                    field: "ConsigneeCustomerIncoterm",
                    cellTemplate: `<div  ng-if="!row.entity.IsInbound" class="ui-grid-cell-contents"  data-toggle="tooltip" data-placement="top" title="{{row.entity.ConsigneeCustomerIncoterm}}">{{row.entity.ConsigneeCustomerIncoterm}}</div> 
                                  `,
                    width: 140,                  
                    enableCellEdit: false,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                    filterHeaderTemplate: `
                    <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                        <div class="input-group-sm">
                            <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                        </div>
                    </div>`
                }, 
                {
                    name: "TRANSPORTMODE",
                    displayName: "* Transport Mode",
                    field: "TransportMode",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 120,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyIncotermCtrl.loadTransportModes()" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {

                            if (cellValue) {
                                return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            }
                            return null;
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiCompanyIncotermList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    },
                },       
                {
                    name: "ORIGIN",
                    displayName: "Origin",
                    visible: this.isInbound,                    
                    field: "OriginDestinationPort",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 160,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyIncotermCtrl.loadPlaceOfLoading(searchText,row.entity.ShipperCountryId,row.entity.ConsigneeCountryId)" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {

                            if (cellValue) {
                                return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            }
                            return null;
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiCompanyIncotermList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    },
                }, {
                    name: "DESTINATION",
                    displayName: "Destination",
                    visible: !this.isInbound,                    
                    field: "OriginDestinationPort",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 160,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyIncotermCtrl.loadPlaceOfLoading(searchText,row.entity.ShipperCountryId,row.entity.ConsigneeCountryId)" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {

                            if (cellValue) {
                                return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            }
                            return null;
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiCompanyIncotermList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    },
                }, 
                {
                    name: "INCOTERM",
                    displayName: "* Incoterm",
                    field: "Incoterm",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 90,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyIncotermCtrl.loadIncoterms()" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {

                            if (cellValue) {
                                return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            }
                            return null;
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiCompanyIncotermList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    },
                },       
                {
                    name: "TermsOfDelivery",
                    displayName: 'Terms of Delivery' ,
                    field: "TermsOfDelivery",
                    enableCellEditOnFocus: true,
                    enableCellEdit: true,
                    width: 160,
                    cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD.Display}}">{{MODEL_COL_FIELD.Display}}</div>`,
                    editableCellTemplate: `
                                <form name="inputForm">
                                    <gts-dropdown ui-select-wrap name="{{MODEL_COL_FIELD.$$hashKey}}" append-to-body="true" ng-model="MODEL_COL_FIELD" form="inputForm" load-data="grid.appScope.companyIncotermCtrl.loadTermsOfDeliveries(row.entity.Incoterm.Id,row.entity.ShipperEntityId,row.entity.ConsigneeEntityId)" pagination-search="true" refresh-delay="1000"></gts-dropdown>
                                </form>`,
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                    filter: {
                        condition: (searchTerm, cellValue) => {

                            if (cellValue) {
                                return (cellValue.Display.toLocaleLowerCase().indexOf(searchTerm.toLocaleLowerCase()) >= 0 || searchTerm.length === 0);
                            }
                            return null;
                        }
                    },
                    sortingAlgorithm: (a, b, rowA, rowB, direction) => {
                        var nulls = this.apiCompanyIncotermList.core.sortHandleNulls(a, b);
                        if (nulls !== null) {
                            return nulls;
                        } else {
                            if (a === b) {
                                return 0;
                            }

                            if (direction === "desc") {
                                if (a.Display < b.Display) return -1;
                                if (a.Display > b.Display) return 1;
                            } else if (direction === "asc") {
                                if (a.Display > b.Display) return 1;
                                if (a.Display < b.Display) return -1;
                            }

                            return 0;
                        }
                    },
                },                    
                {
                    name: "ACTIVE",
                    displayName: "* Active",
                    field: "IsActive",
                    width: 70,
                    cellTemplate: `<center><input type="checkbox" ng-change="grid.appScope.companyIncotermCtrl.makeRowDirty(row)" ng-model="row.entity.IsActive"></center>`,
                    editableCellTemplate: ` 
                            <form name="inputForm">
                                <p class="input-group-sm">
                                    <input type="checkbox" ng-model="row.entity.IsActive">
                                </p>
                            </form>`,
                    enableCellEdit: true,
                    enableCellEditOnFocus: true,
                    cellEditableCondition: true,
                }, {
                    name: "INFO",
                    displayName: "",
                    field: "",
                    cellTemplate: `
                        <div class="GridButton"> 
                            <gts-stamp-info createstampfullname="row.entity.CreateStampFullName" createstampdate="row.entity.CreateStampDate"
                                updatestampfullname="row.entity.UpdateStampFullName" updatestampdate="row.entity.UpdateStampDate">
                            </gts-stamp-info>
                        </div>`,
                    width: 38,
                    enableSorting: false,
                },]
            };

            makeRowDirty(row: uiGrid.IGridRow) {
                this.apiCompanyIncotermList.rowEdit.setRowsDirty([row.entity]);
            }

            entityChange(model: interfaces.applicationcore.IDropdownModel) {
                this.entity = model;

                if (model)
                    this.entityId = model.Id;
                else
                    this.entityId = undefined;
            }
                      
            
            //Submit
            submitCompanyIncoterms() {
                //Get dirty products.
                var companyIncotermList: Array<interfaces.master.ICompanyIncoterm> = [];
                if (this.apiCompanyIncotermList && this.apiCompanyIncotermList.rowEdit.getDirtyRows().length > 0) {
                    companyIncotermList = _.map(this.apiCompanyIncotermList.rowEdit.getDirtyRows(), (o) => {
                        return o.entity;
                    });
                }

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'company.incoterm'
                }, () => {
                    //Save Dirty Products
                    return this.CompanyIncotermService.SaveCompanyIncoterms().save(companyIncotermList, (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);

                        this.$timeout(() => {
                            if (this.apiCompanyIncotermList && !data.HasErrorMessage) {
                                this.apiCompanyIncotermList.rowEdit.setRowsClean(companyIncotermList);
                                this.loadCompanyIncoterms();
                                this.IsSetRowInactive = false;
                            }
                        });
                    }, (errorResponse) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                    }).$promise;
                });
            };

            Close() {
                this.entity = undefined;
                this.entityId = undefined;
                this.$anchorScroll("topAnchor");
                this.$state.go("^");
            }
        }

        angular.module("app").controller("companyIncotermCtrl", controllers.master.companyIncotermCtrl);
    }
}